import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77')
];

export const server_loads = [2];

export const dictionary = {
		"/": [~14],
		"/admin/user": [22,[4]],
		"/api": [23,[5],[6]],
		"/api/open": [24,[5],[6]],
		"/api/open/reference": [25,[5],[6]],
		"/api/open/try": [26,[5],[6]],
		"/api/postman": [27,[5],[6]],
		"/api/reference": [28,[5],[6]],
		"/api/status": [29,[5],[6]],
		"/api/try": [30,[5],[6]],
		"/(office)/company/[companyId]": [~15,[2],[3]],
		"/excel": [31,[7],[8]],
		"/excel/authentication": [32,[7],[8]],
		"/excel/installation": [33,[7],[8]],
		"/excel/queue": [34,[7],[8]],
		"/excel/search": [35,[7],[8]],
		"/login/callback": [36],
		"/me/api-key": [~37,[9]],
		"/me/billing": [~38,[9]],
		"/me/history": [~39,[9]],
		"/me/list": [~40,[9]],
		"/me/notification": [~41,[9]],
		"/me/profile": [~42,[9]],
		"/me/subscription": [~43,[9]],
		"/me/usage": [~44,[9]],
		"/(office)/office": [16,[2],[3]],
		"/(office)/office/bulk": [18,[2],[3]],
		"/(office)/office/preference": [19,[2],[3]],
		"/(office)/office/[taxId]": [~17,[2],[3]],
		"/(office)/person": [20,[2],[3]],
		"/(office)/person/[personId]": [~21,[2],[3]],
		"/subscription": [~45,[10],[11]],
		"/subscription/about": [~46,[10],[11]],
		"/subscription/checkout": [~47,[10],[11]],
		"/subscription/checkout/[subscriptionId]": [48,[10],[11]],
		"/subscription/credit": [49,[10],[11]],
		"/subscription/estimate": [~50,[10],[11]],
		"/subscription/faq": [51,[10],[11]],
		"/subscription/privacy": [52,[10],[11]],
		"/subscription/tos": [53,[10],[11]],
		"/ui/accordion": [54,[12],[13]],
		"/ui/anchor": [55,[12],[13]],
		"/ui/button": [56,[12],[13]],
		"/ui/cache": [57,[12],[13]],
		"/ui/card": [58,[12],[13]],
		"/ui/chart": [59,[12],[13]],
		"/ui/checkbox": [60,[12],[13]],
		"/ui/code": [61,[12],[13]],
		"/ui/copy": [62,[12],[13]],
		"/ui/credit": [63,[12],[13]],
		"/ui/divider": [64,[12],[13]],
		"/ui/form": [65,[12],[13]],
		"/ui/info": [66,[12],[13]],
		"/ui/input-chip": [68,[12],[13]],
		"/ui/input": [67,[12],[13]],
		"/ui/list": [69,[12],[13]],
		"/ui/notice": [70,[12],[13]],
		"/ui/paragraph": [71,[12],[13]],
		"/ui/select": [72,[12],[13]],
		"/ui/status": [73,[12],[13]],
		"/ui/stepper": [74,[12],[13]],
		"/ui/table": [75,[12],[13]],
		"/ui/text-area": [76,[12],[13]],
		"/ui/tooltip": [77,[12],[13]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';